@import '../../../styles/_variables';

.wrap {
  width: 100%;
  position: relative;
  padding: 85px 0;

  .title {
    font-weight: 400;
    line-height: 150%;
    color: $seccolor;
    text-align: center;
  }

  .text {
    font-weight: 300;
    line-height: 150%;
    text-align: center;
  }

  .responsiveTable {
    max-width: 1092px;
    width: 100%;
    margin: 50px auto 0;
  }

  .tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 18px;
    margin-bottom: 30px;
  }

  .tab {
    padding: 8px 20px;
    border: 0;
    background-color: #E7E7E7;
    border: 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: $seccolor;
    font-family: $inter;
    font-size: 14px;
    font-style: normal;
    line-height: 140%;

    &.active {
      color: #2E2E2F;
      background: #D3F350;
    }
  }

  .block-0 {
    max-width: 315px;
    width: 100%;
  }

  .block-1, .block-2, .block-4 {
    max-width: 181px;
    width: 100%;
  }

  .block-3 {
    max-width: 235px;
    width: 100%;
  }

  .flex {
    display: flex;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45px;
    padding: 11px 5px;
    text-align: center;
    color: #313131;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid rgba(49, 49, 49, 0.20);

    &:first-child {
      font-size: 14px;
      justify-content: flex-start;
      color: #313131;
    }
  }

  .head {
    background: #E7E7E7;

    .row {
      color: #77F;
      font-style: normal;
      text-align: center;
      font-size: 17px;
      font-weight: 800;
      border-bottom: 0;

      &:first-child {
        color: #313131;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        justify-content: flex-start;
      }

      &:nth-child(even) {
        border-bottom: 1px solid rgba(255, 255, 255, 1);
      }
    }
  }

  .body {
    .flex {
      .row {
        &:first-child {
          justify-content: flex-start;
        }
      }

      &:nth-child(1) {
        .row {
          color: #77F;
          font-weight: 800;

          &:first-child {
            color: #313131;
            font-weight: 400;
          }
        }
      }
    }
  }

  @media (min-width: $land-768) {
    .tabs {
      display: none;
    }

    .text {
      max-width: 625px;
      margin-right: auto;
      margin-left: auto;
      margin-top: 10px;
    }
    
    .body {
      .flex {
        .row {
          &:nth-child(even) {
            background: #E7E7E7;
            border-color: #fff;
          } 
        }
      }
    }
  }

  @media (max-width: $land-768) {
    .wrap {
      padding: 50px 0;
    }

    .tabs {
      .tab:first-child {
        display: none;
      }
    }

    .body {
      // background: #282828;

      .flex:first-child {
        .row {
          // color: #C0AB72;
          font-weight: 800;

          &:nth-child(1) {
            color: #313131;
            font-weight: 400;
          }
        }
    
      }
      .row {
        font-size: 14px;
      }
    }

    .body .flex .row:first-child {
      display: flex;
      width: 50%;
    }

    .row {
      align-items: center;
      justify-content: center;
      
      &:not(.activeColumn) {
        display: none;
      }
    }

    .head {
      .row:first-child {
        display: flex;
        width: 50%;
      }
    }

    .row.activeColumn {
      display: flex;
      max-width: unset;
      width: 50%;
      background-color: #E7E7E7;
    }
  }
}