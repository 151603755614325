@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    color: $seccolor;
    margin: 0;
    font-weight: 400;
    margin-bottom: 30px;
  }

  .text {
    color: #000;
    font-weight: 200;
    margin-top: 15px;
    line-height: 150%;
  }

  img {
    max-width: 442px;
    width: 100%;
  }
  
  @media screen and (min-width: $land-767) {
    .item {
      max-width: 525px;
      width: 50%;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0;

    .container {
      flex-direction: column;
    }

    .text {
      margin-top: 12px;
    }
  }
}