@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 35px;
  }

  .row {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    gap: 35px;

    &Item {
      position: relative;

      .sign {
        position: absolute;
        left: 30px;
        bottom: 30px;
        color: $seccolor;
        font-family: $roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        padding: 5px 12px;
        border-radius: 12px;
        background: #D3F350;
      }
    }
  }

  .title {
    color: $colored;
  }

  .text {
    color: #000;
    line-height: 150%;
  }

  .imgWrap {
    flex: 0 0 auto;
    width: 100%;
  }
  
  @media screen and (min-width: $land-768) {
    .row {
  
      &Item {
        width: 50%;
  
        &.imageFirst {
          width: 64%;
          flex-shrink: 0;
        }
  
        &.rowItemFirst {
          width: 33%;
          max-width: 400px;
          flex-shrink: 0;
        }
      }
    }

    .title {
      margin-bottom: 20px;

      span {
        display: block;
        color: $seccolor;
        font-size: 40px;
        margin-top: 15px;
      }
    }
  }
  
  @media screen and (max-width: $land-768) {
    padding: 40px 0;

    .container {
      gap: 20px;
    }

    .row {
      flex-direction: column;
      gap: 20px;

      &Item {
        .sign {
          font-size: 14px;
          left: 15px;
          bottom: 15px;
        }
      }
    }

    .title {
      max-width: unset;
      margin-bottom: 15px;
    }
  }
}

:global(body.ru) {
  @media screen and (min-width: $land-769) {
    .row {
      .title {
        font-size: 65px;
        position: relative;
        z-index: 2;
      }
    }
  }
}