@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 120px 0 0;
  background: linear-gradient(90deg, #191919 1.71%, #383838 52.33%, #19191B 100%);

  .container {
    position: relative;
    // overflow: hidden;
  }

  .title {
    color: $colored;
    margin: 0;
    width: 100%;
    text-align: center;
    font-weight: 700;
    position: relative;
    z-index: 1;
  }

  .subtitle {
    color: $colored;
    margin: 0;
    width: 100%;
    text-align: center;
    font-weight: 400;
    position: relative;
    z-index: 1;
    margin-top: 10px;
  }

  .text {
    color: $maintext;
    font-weight: 300;
    margin-top: 10px;
    line-height: 150%;
  }
  
  @media screen and (min-width: $land-767) {
    margin-bottom: 130px;

    .container {
      min-height: 843px;
    }

    .row {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 75px;
      gap: 142px;
      position: relative;
      z-index: 1;
    }

    .left {
      max-width: 325px;
    }

    .right {
      max-width: 270px;
      align-self: flex-end;
    }

    .abs {
      position: absolute;
      bottom: -130px;
      left: 50%;
      transform: translateX(-50%);
      height: 730px;
      z-index: 0;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 110px 0 20px;

    .container {
      text-align: center;
    }

    .abs {
      margin-top: 20px;

      img {
        width: 100%;
      }
    }

    .text {
      margin-top: 20px;
    }
  }
}