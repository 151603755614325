@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 50px 0 85px;

  .container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    max-width: 1060px;
  }

  .item {
    color: #000;
    font-family: $inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:nth-child(2) {
      margin-top: 120px;
    }

    &Icon {
      width: 75px;
      height: 75px;
      margin-bottom: 25px;
    }

    &Text {
      max-width: 210px;
      text-align: center;
    }
  }
  
  @media screen and (min-width: $land-767) {
    transform: translateY(-130px);

    .container {
      transform: translateX(5%);
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0;

    .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 20px;
    }

    .item {
      font-size: 16px;
      width: 46%;
      height: 100%;

      &:nth-child(2) {
        margin-top: 0;
      }

      &Icon {
        width: 50px;
        height: 50px;
        margin-bottom: 25px;
      }

      &Text {
        max-width: unset;
      }
    }
  }
}