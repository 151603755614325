@import '../../../styles/variables';

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .container {
    display: flex;
    gap: 35px;
  }

  .tab {
    &sContent {
      max-width: 730px;
      width: 100%;
    }

    &Buttons {
      width: 280px;
      flex-shrink: 0;

      button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        background-color: #2E2E2F;
        border-radius: 40px;
        margin-bottom: 10px;
        color: #fff;

        &:last-child {
          margin-bottom: 0;
        }

        &.tabActive {
          color: #171717;
          font-weight: 700;
          background-color: #9393FF;
        }
      }
    }

    &Item {
      display: none;

      &.tabItemActive {
        display: block;
      }

      &Title {
        font-family: $roboto;
        color: #2E2E2F;
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 29px;
      }

      &Text {
        color: #313131;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        margin-bottom: 30px;
      }

      &Column {
        display: flex;
        align-items: stretch;
        gap: 35px;
      }
    }
  }

  @media (max-width: $land-768) {
    padding: 50px 0;

    .container {
      flex-direction: column;
    }

    .tab {
      &sContent {
        padding-top: 10px;
      }

      &Item {

        &Column {
          flex-direction: column;
          gap: 35px;
        }
      }

      &Title {
        font-size: 25px;
      }

      &Text {
        font-size: 14px;
      }
  
      &Buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px 20px;
        width: 100%;
  
        button {
          font-size: 16px;
          line-height: 110%;
          margin: 0;
          min-height: 68px;
        }
      }
  
      &Item {
  
        &Title {
          font-size: 20px;
        }
      }
    }
  }
}