@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .title {
    color: $seccolor;
    font-weight: 400;
    line-height: 150%;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-top: 30px;

    &Item {
      max-width: 525px;

      &Title {
        font-weight: 700;
        color: #313131;
      }

      &Text {
        font-weight: 300;
        color: #313131;
        margin-top: 15px;
        line-height: 150%;
      }
    }
  }
  
  @media screen and (max-width: $land-768) {
    padding: 60px 0;

    .list {
      grid-template-columns: 1fr;
    }
  }
}