@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 95px 0;

  .title {
    color: #2E2E2F;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
  }

  .text {
    color: #000;
    font-weight: 300;
    line-height: 150%;
    margin-top: 10px;
    text-align: center;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 40px;

    img {
      width: 100%;
      max-width: 175px;
    }
  }
  
  @media screen and (min-width: $land-767) {
    .list {
      grid-template-columns: repeat(4, 175px);
      justify-content: space-between;
      gap: 50px;
      margin: 75px auto 0;
      max-width: 985px;
    }

    .text {
      max-width: 635px;
      margin: 10px auto 0;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0;
  }
}