@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-bottom: 1px solid #313131;

    &Title {
      color: #313131;
      font-weight: 400;
      line-height: 150%;
    }

    &Text {
      font-family: $inter;
      font-weight: 300;
      line-height: 150%;
      color: #313131;
    }

    &Icon {
      width: 50px;
      height: 50px;
      display: none;
    }
  }
  
  @media screen and (min-width: $land-767) {
    .item {
      padding: 23px 0;

      &:hover {
        & > .itemImage {
          opacity: 1;
        }
      }

      &Text {
        max-width: 315px;
        width: 65%;
      }

      &Image {
        width: 210px;
        height: 200px;
        position: absolute;
        opacity: 0;
        top: -30%;
        transition: opacity 0.4s ease-in-out;

        &.image-0 {
          left: 35%;
        }

        &.image-1 {
          left: 28%;
        }

        &.image-2 {
          left: 40%;
        }
      }

      &Block {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &Icon {
        display: block;
      }
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0;

    .item {
      flex-direction: column;
      gap: 15px;
      align-items: flex-start;
      border-bottom: 0;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &Image {
        display: none;
      }
    }

    .text {
      margin-top: 12px;
    }
  }
}