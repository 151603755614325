@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .title {
    color: $seccolor;
    text-align: center;
    font-weight: 400;
    line-height: 150%;
  }

  .text {
    color: #000;
    margin-top: 15px;
    text-align: center;
    font-weight: 300;
    line-height: 150%;
  }

  .list {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    max-width: 1060px;
    margin: 70px auto 0;
    gap: 15px;
  }

  .item {
    color: #000;
    font-family: $inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &Icon {
      width: 75px;
      height: 75px;
      margin-bottom: 25px;
    }

    &Text {
      max-width: 210px;
      text-align: center;
    }
  }
  
  @media screen and (min-width: $land-767) {
    .title, .text {
      max-width: 550px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0;

    .container {
      grid-template-columns: 1fr;
      gap: 20px;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    .item {
      font-size: 16px;
      width: 46%;
      height: 100%;

      &Icon {
        width: 50px;
        height: 50px;
        margin-bottom: 25px;
      }

      &Text {
        max-width: unset;
      }
    }
  }
}