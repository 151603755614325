@import "../../../styles//variables";

.wrap {
  padding: 80px 0;

  .title {
    margin-bottom: 40px;
    font-weight: 700;
    color: $seccolor;
  }

  @media screen and (max-width: $land-767) {
    padding: 40px 0;

    .title {
      margin-bottom: 20px;
    }
  }
}