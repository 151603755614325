@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 75px 0 0;
  background-color: #2E2E2F;
  margin: 190px 0 95px;

  .first {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 190px;

    .item {
      max-width: 525px;
      width: 50%;
    }
  }

  .title {
    font-family: $roboto;
    color: #fff;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 30px;
  }

  .subtitle {
    color: #fff;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 15px;
  }

  .text {
    color: #fff;
    font-weight: 300;
    line-height: 150%;
  }

  .row {

    &Item {

      &.rowItemContent {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
      }
  
      &Image {
        padding: 0;
        // width: 50%;
        flex-shrink: 0;
        // max-height: 378px;
        height: 100%;
        overflow: hidden;
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          
        }
      }
  
      &Title {
        color: #FFF;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        margin-bottom: 30px;
      }
  
      &Text {
        color: #FFF;
        font-size: 17px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; 

        p {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  
  
  @media screen and (min-width: $land-768) {
    .row {
      display: flex;
      align-items: stretch;
      flex-direction: row;
    
      &:nth-child(odd) {
        flex-direction: row-reverse;
      }
  
      &Item {
        padding: 3%;
        width: 50%;

        &Image {
          padding: 0;
          height: auto;
        }
      }
    }
  }

  @media screen and (min-width: 1300px) {
    .row {
    
      &:nth-child(odd) {
  
        .rowItemContent {
          padding-left: 10%;
        }
      }
  
      &Item {
  
        &.rowItemContent {
          padding-right: 10%;
        }
      }
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 50px 0 0;
    margin: 80px 0 40px;

    .first {
      flex-direction: column;
      margin-bottom: 80px;
      gap: 30px;

      .item {
        width: 100%;
        max-width: unset;
      }
    }

    .container {
      flex-direction: column;
    }

    .title {
      font-size: 25px;

      &:not(.colored) {
        margin-bottom: 15px;
      }
    }

    .text {
      font-size: 14px;
    }

    .row {
      flex-direction: column;

      &Item {
        padding: 42px 20px 50px;
        gap: 15px;

        &Image {
          padding: 0;
        }

        &Title {
          margin: 0;
          font-size: 25px;
        }

        &Text {
          font-size: 14px;
        }
      }
    }
  }
}