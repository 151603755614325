$colored: #9393FF;
$seccolor: #2E2E2F;
$maintext: #fff;
$black: #000;

$inter: "Inter", sans-serif;
$roboto: "Roboto", sans-serif;

$max-width: 1920px;
$main-width: 1220px;
$land-992: 992px;
$land-769: 769px;
$land-768: 768px;
$land-767: 767px;
$minwidth: 375px;