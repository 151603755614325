@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .container {
    overflow: hidden;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    color: $colored;
  }

  .text {
    color: #000;
    line-height: 150%;
  }

  .list {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 55px;
    margin-top: 60px;

    &Item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &:nth-child(odd) {
        flex-direction: row-reverse;
      }

      &Title {
        position: relative;
        color: $seccolor;
      }
  
      &Text {
        margin-top: 15px;
        color: #000;
        line-height: 150%;
      }

      .textBlock {
        width: 40%;
        text-align: left;
        padding-left: 5%;
      }
    }

    .imgWrap {
      flex: 0 0 auto;

      img {
        width: 100%;
      }
    }
  }
  
  @media screen and (min-width: $land-768) {
    .title {
      max-width: 530px;
      // width: 50%;

      span {
        display: block;
        color: $seccolor;
        font-size: 40px;
      }
    }

    .text {
      max-width: 530px;
      width: 50%;
    }
  }
  
  @media screen and (max-width: $land-768) {
    padding: 40px 0;

    .row {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    .title {
      max-width: unset;
    }

    .list {
      margin-top: 50px;
      gap: 35px;
  
      &Item {
        flex-direction: column-reverse;
        gap: 35px;
  
        &:nth-child(odd) {
          flex-direction: column-reverse;

          .textBlock {
            text-align: left;
          }
        }
  
        &Title {
          max-width: unset;
        }
    
        &Text {
          margin-top: 12px;
          max-width: unset;
          padding: 0;
        }
        
        .textBlock {
          width: 100%;
          padding-left: 0;
          text-align: right;
        }
      }
    }
  }
}