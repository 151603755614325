@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 35px;
  }

  .item {
    position: relative;

    img {
      width: 100%;
    }

    .sign {
      position: absolute;
      left: 30px;
      bottom: 30px;
      color: $seccolor;
      font-family: $roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      padding: 5px 12px;
      border-radius: 12px;
      background: #D3F350;

      strong {
        display: block;
      }
    }
  }
  
  @media screen and (min-width: $land-768) {
    .item {
      width: 48%;
      max-width: 595px;

      &:nth-child(2) {
        width: 66%;
        flex-shrink: 0;
        max-width: 802px;
      }

      &:first-child {
        width: 32%;
        max-width: 383px;
        flex-shrink: 0;
      }

      .sign {
        max-width: 290px;
        width: 100%;
      }
    }
  }
  
  @media screen and (max-width: $land-768) {
    padding: 60px 0;

    .container {
      flex-direction: column;
      gap: 20px;
    }

    .item {
      .sign {
        font-size: 14px;
        left: 15px;
        bottom: 15px;
      }
    }
  }
}