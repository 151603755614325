@import '../../../styles/_variables';

.wrap {
  width: 100%;
  position: relative;
  padding: 85px 0;

  .title {
    font-weight: 400;
    line-height: 150%;
    color: $seccolor;
    text-align: center;
  }

  .text {
    font-weight: 300;
    line-height: 150%;
    text-align: center;
  }

  .icon {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #9393FF;
  }

  .titlesTitle {
    color: #184037;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 20px 0 8px;
  }

  .titlesText {
    color: rgba(0, 0, 0, 0.70);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
    text-align: center;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .responsiveTable {
    max-width: 1092px;
    width: 100%;
    margin: 50px auto 0;
  }

  .tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 18px;
    margin-bottom: 30px;
  }

  .tab {
    padding: 8px 20px;
    border: 0;
    background-color: #E7E7E7;
    border: 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: $seccolor;
    font-family: $inter;
    font-size: 14px;
    font-style: normal;
    line-height: 140%;

    &.active {
      color: #2E2E2F;
      background: #D3F350;
    }
  }

  .block-0 {
    max-width: 215px;
    width: 100%;
  }

  .block-1, .block-2, .block-3, .block-4 {
    max-width: 250px;
    width: 100%;
  }

  .btn a {
    padding: 14px 30px;
    font-weight: 400;
  }

  .block-2, .block-3, .block-4 {
    
    .btn a {
      color: #fff;
      background-color: #2E2E2F;
    }
  }

  .block-3 {
    max-width: 235px;
    width: 100%;
  }

  .flex {
    display: flex;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45px;
    padding: 11px 5px;
    text-align: center;
    color: #313131;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid rgba(49, 49, 49, 0.20);

    &:first-child {
      font-size: 14px;
      justify-content: flex-start;
      color: #313131;
      text-align: left;
    }
  }

  .head {
    .row {
      color: #77F;
      font-style: normal;
      text-align: center;
      font-size: 17px;
      font-weight: 800;
      border-bottom: 0;

      &:first-child {
        color: #313131;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        justify-content: flex-start;
      }
    }
  }

  .body {
    margin-top: 35px;
    .flex {
      .row {
        &:first-child {
          justify-content: flex-start;
        }
      }
    }
  }

  .block-0 {
    font-weight: 600;
  }

  @media (min-width: $land-768) {
    .tabs {
      display: none;
    }

    .text {
      max-width: 625px;
      margin-right: auto;
      margin-left: auto;
      margin-top: 10px;
    }
    
    .body {
      .flex {
        &:nth-child(odd) {
          border-radius: 8px;
          background: #E7E7E7;
        }
      }
    }
  }

  @media (max-width: $land-768) {
    .wrap {
      padding: 50px 0;
    }

    .tabs {
      .tab:first-child {
        display: none;
      }

      &.mobbtns {
        .tab:first-child {
          display: block;
        }
      }
    }

    .body {
      // background: #282828;

      .flex:first-child {
        .row {
          // color: #C0AB72;
          font-weight: 800;

          &:nth-child(1) {
            color: #313131;
            font-weight: 400;
          }
        }
    
      }
      .row {
        font-size: 14px;
      }
    }

    .body .flex .row:first-child {
      display: flex;
      width: 50%;
    }

    .row {
      align-items: center;
      justify-content: center;
      
      &:not(.activeColumn) {
        display: none;
      }
    }

    .head {
      justify-content: flex-end;
    }

    .column {
      max-width: unset;
      width: 50%;
      &:not(.activeColumn) {
        display: none;
      }
    }

    .row.activeColumn {
      display: flex;
      max-width: unset;
      width: 50%;
      background-color: #E7E7E7;
    }
  
    .titlesTitle {
      font-size: 16px;
      margin: 10px 0 8px;
    }
  
    .titlesText {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .btn a {
      padding: 10px 15px;
    }
  }
}