@import './_variables';

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.font-75-25, .font-75-50 {
    font-family: $roboto;
    font-size: 75px;
}

.font-50-25 {
    font-family: $roboto;
    font-size: 50px;
}

.font-40-25 {
    font-family: $roboto;
    font-size: 40px;
}

.font-30-25 {
    font-family: $roboto;
    font-size: 30px;
}

.font-33 {
    font-family: $inter;
    font-size: 33px;
}

.font-20-14 {
    font-family: $inter;
    font-size: 20px;
}

.font-16-14 {
    font-family: $inter;
    font-size: 16px;
}

@media screen and (max-width: $land-767) {

    .font-75-50 {
        font-size: 50px;
    }

    .font-75-25, .font-40-25, .font-50-25, .font-30-25 {
        font-size: 25px;
    }

    .font-16-14, .font-20-14 {
        font-size: 14px;
    }
}