@import '../../../styles/_variables';

.button {
  text-decoration: none;
  padding: 8px 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $colored;
  color: #0D0D0D;
  font-family: $roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.32px;
  text-transform: capitalize;
  border-radius: 40px;

  &:hover {
    font-weight: 700;
  }

  &.yellow {
    border-radius: 35px;
    background: #D3F350;
    padding: 11px 48px;
  }

  @media (max-width: 768px) {
      border-width: 1px;
      letter-spacing: -0.2px;
  }
}