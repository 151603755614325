@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 210px 0 85px;

  .container {
    position: relative;
    background: linear-gradient(90deg, #191919 1.71%, #383838 52.33%, #383838 89.97%, #19191B 100%);
  }

  .title {
    color: $colored;
    margin: 0;
    width: 100%;
    text-align: left;
    font-weight: 700;
    position: relative;
    z-index: 1;
  }

  .subtitle {
    color: #fff;
    margin: 0;
    width: 100%;
    text-align: left;
    font-weight: 400;
    position: relative;
    z-index: 1;
    margin-top: 10px;
    line-height: 150%;
  }

  .text {
    color: #fff;
    font-weight: 400;
    margin-top: 10px;
    line-height: 150%;
  }

  .abs {

    img {
      width: 100%;
    }
  }
  
  @media screen and (min-width: $land-767) {

    .container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: 5%;
      border-radius: 50px;
      min-height: 551px;
    }

    .abs {
      position: absolute;
      width: 509px;
      height: 587px;
      z-index: 0;
      top: -36px;
      right: 7%;
    }

    .text {
      width: 300px;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 0;
    margin-bottom: 40px;

    .container {
      padding: 90px 20px 0;
      text-align: center;
      background: linear-gradient(90deg, #191919 1.71%, #383838 52.33%, #19191B 100%);
    }

    .abs {
      margin-top: 60px;
      bottom: 0;
      height: auto;
      text-align: center;

      img {
        width: 79%;
      }
      
    }

    .title, .subtitle {
      text-align: center;
    }

    .text {
      margin-top: 20px;
    }
  }
}