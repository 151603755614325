@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .container {
    display: flex;
    align-items: center;
    gap: 70px;
  }

  .title {
    color: $seccolor;
    font-weight: 400;
    line-height: 150%;
  }

  .text {
    color: #000;
    margin-top: 15px;
    font-weight: 300;
    line-height: 150%;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &Item {
      color: #000;
      font-family: $inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      display: flex;
      align-items: center;
      gap: 15px;

      &Icon {
        width: 30px;
        height: 30px;
      }
    }
  }
  
  @media screen and (min-width: $land-767) {
    .title, .text {
      max-width: 550px;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0;

    .container {
      flex-direction: column;
      gap: 20px;
    }

    .list {
      width: 100%;
    }

    .item {
      font-size: 16px;

      &Text {
        max-width: unset;
      }
    }
  }
}