@import '../../../styles/variables';

.accordion {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .item {
    background-color: transparent;
    padding: 20px 30px 20px 50px;
    transition: all 1s ease;
    border-radius: 12px;
    background: #2E2E2F;

    &.itemActive {
      border-color: transparent;

      // .icon {
      //   transform: rotate(90deg);
      // }

      .content {
        margin-top: 25px;
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    color: $maintext;
    font-family: $inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    background: transparent;
    width: 100%;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    cursor: pointer;
    transition: all 0.4s linear;
  }

  .content {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    // padding-right: 16%;
    overflow: hidden;
    height: 0;
    transition: all 0.4s ease;

    .text {
      margin-bottom: 15px;
    }

    p:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  @media (min-width: $land-768) {
    .content {
      max-width: 856px;
    }
  }

  @media (max-width: $land-768) {
    .item {
      padding: 15px 10px 15px 20px;
    }

    .title {
      column-gap: 20px;
      text-align: left;
    }

    .content {
      font-size: 14px;
      padding-right: 40px;
    }
  }
}