@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 210px 0 85px;

  .title {
    color: $colored;
    margin: 0;
    width: 100%;
    text-align: left;
    font-weight: 700;
    position: relative;
    z-index: 1;
  }

  .subtitle {
    color: #2E2E2F;
    margin: 0;
    width: 100%;
    text-align: left;
    font-weight: 400;
    position: relative;
    z-index: 1;
    margin-top: 10px;
    line-height: 150%;
  }

  .text {
    font-family: $inter;
    color: #2E2E2F;
    font-weight: 300;
    margin-top: 10px;
    line-height: 150%;
  }

  .row {

    .item {
      position: relative;

      img {
        border-radius: 20px;
        width: 100%;
        height: 313px;
        object-fit: cover;
        object-position: center;
      }

      &Abs {
        position: absolute;
        left: 30px;
        bottom: 23px;
        border-radius: 12px;
        background: #D3F350;
        max-width: 350px;
        padding: 12px 20px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 8px;
      }

      &Text {
        line-height: 150%;
        
        a {
          color: #2E2E2F;
        }
      }
    }
  }
  
  @media screen and (min-width: $land-767) {

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;

      &.mart {
        margin-top: 55px;
      }

      .item {
        width: 50%;
        max-width: 595px;

        img {
          border-radius: 40px;
          height: auto;
        }

        .title {
          white-space: nowrap;
        }

        &.blockText {
          max-width: 525px;
        }
      }
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 0 0 40px;

    .container {
      padding: 90px 20px 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .row {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .title, .subtitle {
      text-align: center;
    }

    .text {
      margin-top: 20px;
    }
  }
}

:global(body.ru) {
  @media screen and (min-width: $land-769) {
    .row {
      .item .title {
        white-space: normal;
      }
    }
  }
}