@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 45px;
    background: $seccolor;

    &Item {
      padding: 6%;
    }
  }

  .title {
    color: $colored;
    font-weight: 700;
  }

  .subtitle {
    color: #fff;
    margin-top: 5px;
  }

  .list {
    margin-top: 35px;
    display: flex;
    justify-content: space-between;
    gap: 15px;

    &Item {
      display: flex;
      align-items: center;
      gap: 30px;
      border-radius: 12px;
      background: $seccolor;
      padding: 7px 10px;
      width: 30%;
      max-width: 350px;

      &Index {
        display: flex;
        flex-shrink: 0;
        color: #D3F350;
        font-size: 40px;
        font-style: normal;
        font-weight: 300;
        line-height: 120%; 
      }

      &Title {
        color: #D3F350;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        white-space: nowrap;
      }
    }
  }

  .image {
    width: 100%;
  }
  
  @media screen and (min-width: $land-768) {
    .item {
      max-width: 525px;
      width: 50%;
    }

    .imgWrap {
      max-width: 530px;
      width: 45%;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0;

    .row {
      flex-direction: column;

      &Item {
        padding: 40px 0 0;
        width: 100%;
        text-align: center;
      }
    }

    .subtitle {
      margin-top: 10px;
    }

    .imgWrap {
      width: 100%;
    }

    .list {
      margin-top: 15px;
      gap: 15px;
      flex-direction: column;
  
      &Item {
        gap: 15px;
        width: 100%;
        max-width: unset;
        padding: 10px 15px;
  
        &Index {
          width: auto;
          font-size: 16px;
        }
  
        &Title {
          font-size: 14px;
        }
      }
    }
  }
}